<template>
    <div class="row mt-2">
		<label class="col-4 col-form-label" for="horse_cats">{{ $t('horse.cat_cheval')}}</label>
		<div class="col-8">
			<e-select
				id="horse_cats"
				class="mb-3"
				v-model="selected_categorie"
				track-by="categorie_id"
				label="categorie_libelle"
				:placeholder="$t('horse.categorie_cheval')"
				:options="categorie_to_show"
				:searchable="true"
				:allow-empty="false"
				:loading="working"
				:show-labels="false"
				:disabled="readonly"
	            @input="addCategorie"
			>
				<template slot="noOptions">{{ $t('categorie.aucune_categorie') }}</template>
			</e-select>
		</div>
		<button 
			v-for="(cat, index) in local_selected_horse_categories" :key="'choice_'+index"
			:disabled="readonly"
			@click="deleteCategorie(cat)" class="btn btn-sm btn-primary rounded-pill mr-2">
			<font-awesome-icon v-if="cat.categorie_public" :icon="['far', 'globe']" style="color: #2ecc19;"/>
			{{ cat.categorie_libelle }} 
			<font-awesome-icon :icon="['fal', 'times']" />
		</button>
		<div v-if="selectPlaceholder" class="row">
			<span v-if="selectPlaceholder">{{ $t('horse.aucune_categorie') }}</span>
		</div>
	</div>
	
		
 
</template>

<script type="text/javascript">
    import HorseMixin from "@/mixins/Horse.js";

    export default {
        mixins: [HorseMixin],
        props: [
            'horse_id',
			'readonly'
        ],
        data (){
            return {
            	horse_categories: [],
            	selected_categorie: 0,
				selected_horse_categories: [],
				working: true,
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
				this.updateHorseCategories()
				this.horse_categories = await this.getAllCategorie()
				this.working = false
			},

			async updateHorseCategories() {
				this.selected_horse_categories = await this.getHorseCategories(this.horse_id)
			},

			addCategorie() {
				if(this.selected_categorie.categorie_id != 0){
					this.addHorseCategorie(this.horse_id, this.selected_categorie.categorie_id).finally((res) => {
						this.selected_categorie = 0
						this.updateHorseCategories()
					})
				}
			},
			deleteCategorie(cat) {
				this.removeHorseCategorie(this.horse_id, cat.categorie_id).finally(() => {
					this.updateHorseCategories()
				})
			}
        },
        computed: {
        	categorie_to_show: function() {
        		return this.diffArrayNonExist(this.horse_categories, this.local_selected_horse_categories)
			},
			selectPlaceholder() {
				return this.readonly && this.local_selected_horse_categories.length === 0
			},
			local_selected_horse_categories() {
				return this.horse_categories.filter((cat1) => {
					const isok = this.selected_horse_categories.filter(cat2 => cat2.categorie_id == cat1.categorie_id)
					return isok.length > 0
				})
			}
        }
    }
</script>
